import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// helpers
import { APICore } from '../helpers/api/apiCore';

// hooks
import { useUser } from '../hooks';
import useAuth from "../hooks/useAuth";
import axios from 'axios';
import { useEffect } from 'react';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles: string[];
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const homePage=['/','/','/','/tickets/bugs','/tickets/bugs']
const PrivateRoute =  ({ component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    let location = useLocation();
    const [loggedInUser] = useUser();
    const {auth, setAuth} = useAuth();
    const navigate = useNavigate();

    const api = new APICore();

    /**
     * not logged in so redirect to login page with the return url
     */
    //  if ( api.isUserAuthenticated() === false) {
    //     return <Navigate to={'/login'} state={{ from: location }} replace />;
    // }
    useEffect(() => {
        console.log(api.isUserAuthenticated());
        if ( api.isUserAuthenticated() === false) {
            navigate('/login');
        }
        else {
            if (!auth.email) {
                axios.get('/home/', {
                    withCredentials: true
                }).then((response) => {
                        const main_role = response.data.main_role;
                        const current_role = main_role;
                        const first_name = response.data.first_name;
                        const last_name = response.data.last_name;
                        const email = response.data.email;
                        const projects = response.data.projects;
                        const users = response.data.users;
                        const last_login = response.data.last_login;
                        const username=response.data.username;
                        const notifications=response.data.notifications
                        setAuth({ username,last_login,first_name, last_name, email, main_role, current_role, projects, users,notifications });
                    
                }).catch(err=>{
                    console.log(err);
                    if(err=='Invalid credentials'){
                        localStorage.removeItem('LoggedIn');
                        navigate('/login');
                    }else if(err=='change password'){
                      navigate('/ChangePassword');
                    }
                    else navigate('/error-500');
                });
            }
        }
    }, [auth.email, setAuth]);
    
        
        //// user data => projects => users 
        //check state mo3ayna
        //ah yeb2a kamel
        //law la2 ye3mel fetch we law
        //true 7ot el data fel state
        //else yeb2a navigate lel login
    
    // check if route is restricted by role
    let rolle="";
    rolle=auth.current_role;
    if (roles&&rolle) {
        let f=0;
        for(let i in roles){
            if(roles[i]===rolle){
                f=1;
            }
        }
        // role not authorised so redirect to home page
        if(f===0)
      {navigate(homePage[parseInt(rolle)]);}
    }
    return <RouteComponent />;
};

export default PrivateRoute;
